<template>
  <div class="manage-investor" :class="{ skeleton: !state.loaded }" :data-project-type="$route.params.projectType">
    <template v-if="state.list.length">
      <div class="top">
        <ManageInvestorActions :projectType="$route.params.projectType" :load="load" :openMessageModal="openMessageModal" :sequences="state.check.sequences" :args="state.args" :loaded="state.loaded"/>
      </div>
      <div class="content">
        <div class="table-responsive">
          <table class="table" :class="{'table-hover': state.loaded}">
            <thead class="thead-light">
            <tr>
              <th class="check text-center">
                <input type="checkbox" v-model="state.check.all" @change="onCheckAllChange()"/>
              </th>
              <th class="name">
                <span>{{ $route.params.projectType === "reward" ? "후원자" : "투자자" }}</span>
              </th>
              <template v-if="$route.params.projectType === 'reward'">
                <th>
                  <span>펀딩 프로젝트 수</span>
                </th>
                <th class="title">
                  <span>리워드</span>
                </th>
                <th class="amount">
                  <span>후원금액</span>
                </th>
                <th class="date">
                  <span>후원일시</span>
                </th>
                <th class="pay">
                  <span v-if="project.fundingType === 'K'">결제수단</span>
                  <span v-else>결제상태</span>
                </th>
              </template>
              <template v-else-if="$route.params.projectType === 'mock'">
                <th class="amount">
                  <span>모의투자금액</span>
                </th>
                <th class="date">
                  <span>모의투자일시</span>
                </th>
              </template>
              <template v-else>
                <th class="cd">
                  <span>투자자유형</span>
                </th>
                <th class="amount">
                  <span>투자금액(원)</span>
                </th>
                <th class="amount">
                  <span>배정금액(원)</span>
                </th>
                <th class="date">
                  <span>투자일</span>
                </th>
                <th class="status">
                  <span>상태</span>
                </th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr :class="{'pointer': $route.params.projectType !== 'invest' && state.loaded}" v-for="(i, idx) in state.list" :key="idx"
                @click="state.loaded && $route.params.projectType === 'reward'
                      && $router.push({path:`/manage/${$route.params.projectType}/${$route.params.projectSeq}/investor/${i.investorSeq}`})">
              <td class="check">
                <label @click.stop>
                  <input type="checkbox" :value="$route.params.projectType === 'invest' ? i.seq : i.investorSeq" v-model="state.check.sequences" @change="onCheckChange"/>
                </label>
              </td>
              <td class="name">
                <router-link :to="`/channel/${i.memberSeq}`" :title="`${$route.params.projectType === 'reward' ? '후원자' : '투자자'} 채널 바로가기`" v-if="$route.params.projectType !== 'invest'">
                  <span>{{ computedInvestorName(i) }}</span>
                </router-link>
                <span v-else>{{ computedInvestorName(i) }}</span>
                <span class="badge badge-primary pointer" @click.stop="openMessageModal(i)">메시지</span>
                <br/>
                <span>{{ i.memberId }}</span>
              </td>
              <template v-if="$route.params.projectType === 'reward'">
                <td>
                  <span>{{ i.supportCount }}개</span>
                </td>
                <td class="title">
                  <span v-if="i.title">{{ i.title.replace("외 0건", "") }}</span>
                </td>
                <td class="amount">
                  <span>{{ $lib.getNumberFormat(i.expenseAmt) }}</span>
                </td>
                <td class="date">
                  <span>{{ i.regDate }}</span>
                </td>
                <td class="pay">
                  <span v-if="i.fundingType === 'K'">{{ i.paymethodText }}</span>
                  <span v-else>{{ i.paymentState }}</span>
                </td>
              </template>
              <template v-else-if="$route.params.projectType === 'mock'">
                <td class="amount">
                  <span>{{ $lib.getNumberFormat(i.expenseAmt) }}</span>
                </td>
                <td class="date">
                  <span>{{ i.regDate }}</span>
                </td>
              </template>
              <template v-else>
                <td class="cd">
                  <span>{{ $definitions.codes.obj.investorSortCd[i.invstrSortCd] }}</span>
                </td>
                <td class="amount">
                  <span>{{ $lib.getNumberFormat(i.subscrAmount) }}</span>
                </td>
                <td class="amount">
                  <span>{{ $lib.getNumberFormat(i.distriAmount) }}</span>
                </td>
                <td class="date">
                  <span>{{ $lib.getDateFormat(i.createDate, "yyyy-MM-dd") }}</span>
                </td>
                <td class="status">
                  <span v-if="i.cancelDate">투자 취소 {{ i.refundResDt ? "(환불 완료)" : "(환불 예정)" }}</span>
                  <span v-else-if='i.distriCount === 0'>투자금반환(배정실패)</span>
                  <span v-else-if='i.distriCount !== i.subscrCount'>투자금반환(부분배정)</span>
                  <span v-else>투자 완료</span>
                </td>
              </template>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bottom">
        <ManageInvestorActions :projectType="$route.params.projectType" :load="load" :openMessageModal="openMessageModal" :sequences="state.check.sequences" :args="state.args" :loaded="state.loaded"/>
      </div>
      <div class="page">
        <Pagination :info="state.paginationInfo" :change="load"/>
      </div>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Card from "@/components/Card";
import Wait from "@/components/Wait";
import http from "@/scripts/http";
import router from "@/scripts/router";
import Pagination from "@/components/Pagination";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import ManageInvestorActions from "@/components/ManageInvestorActions";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageManageInvestor";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, ManageInvestorActions, Pagination, Wait, Card},
  mixins: [mixin],
  props: {
    project: Object
  },
  setup(props) {
    const component = new Component(() => {
      state.args.searchCondition = projectType === "invest" ? "investorName" : "memberName";

      for (let i = 0; i < 20; i += 1) {
        state.list.push({
          memberName: "Wait",
          memberId: "wait@moment",
          title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          regDate: "0000-00-00",
          fundingType: "K",
          paymethodText: "Wait",
          expenseAmt: 0,
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      check: {
        all: false,
        sequences: [],
      },
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 30,
        searchCondition: "",
        searchKeyword: "",
        order: "latest"
      },
      list: [],
      contest: {
        contestSeq: "",
        currencyUnit: "원",
        investorNameAltText: undefined,
        investorNameOpenYn: "Y",
        projectStateHideYn: "N"
      },
      paginationInfo: {}
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    const onCheckChange = () => {
      state.check.all = state.list.length === state.check.sequences.length;
    };

    const onCheckAllChange = () => {
      if (state.check.all) {
        if (projectType === "invest") {
          state.check.sequences = state.list.map(i => i.seq);
        } else {
          state.check.sequences = state.list.map(i => i.investorSeq);
        }
      } else {
        state.check.sequences = [];
      }
    };

    const openMessageModal = (investor) => {
      const params = {
        type: "send",
        members: [],
      };

      if (investor) {
        params.members = [{
          seq: investor.memberSeq,
          name: computedInvestorName(investor),
        }];
      } else if (state.check.sequences.length) {
        if (projectType === "invest") {
          for (let investor of state.list) {
            for (let seq of state.check.sequences) {
              if (investor.seq === seq) {
                params.members.push({
                  seq: investor.memberSeq,
                  name: computedInvestorName(investor),
                });
              }
            }
          }
        } else {
          for (let investor of state.list) {
            for (let seq of state.check.sequences) {
              if (investor.investorSeq === seq) {
                params.members.push({
                  seq: investor.memberSeq,
                  name: computedInvestorName(investor),
                });
              }
            }
          }
        }
      } else {
        return store.commit("setSwingMessage", "메시지 수신자를 선택해주세요.");
      }

      store.commit("openModal", {
        name: "MessageWrite",
        params
      });
    };

    const computedInvestorName = (investor) => {
      return router.app.$route.params.projectType === "invest"
          ? investor.invstrNm
          : (state.contest?.investorNameOpenYn && state.contest?.investorNameOpenYn === "N")
              ? state.contest?.investorNameAltText || investor.memberName.substr(0, 1) + "**"
              : investor.memberName;
    };

    const load = (num) => {
      state.args.pageIndex = num || 1;
      window.scrollTo(0, 0);

      const args = lib.getRenewed(state.args);

      if (args.order === "payMethod" && projectType === "reward" && props.project.fundingType !== "K") {
        args.order = "scheduleState";
      }

      state.check.all = false;
      state.check.sequences = [];

      state.loaded = false;
      http.get(`/api/maker/${projectType}/${projectSeq}/investors`, args).then(({data}) => {
        state.loaded = true;
        state.list = data.body.list;
        state.contest = data.body.contest;
        state.paginationInfo = data.body.paginationInfo;
      }).catch((err) => {
        state.loaded = true;

        if (err?.response?.data?.code === "NO_AUTH_SEARCH_BEFORE_DISTRIBUTE") {
          state.args.searchKeyword = "";
        }
      });
    };

    return {component, state, computedInvestorName, openMessageModal, load, onCheckChange, onCheckAllChange};
  }
});
</script>

<style lang="scss" scoped>
.manage-investor {
  .top {
    padding-bottom: $px18;
  }

  .content {
    table {
      th, td {
        padding-left: $px15;
        padding-right: $px15;

        &.check {
          width: $px43;
        }
      }

      th {
      }

      td {
        vertical-align: middle;

        > span {
          word-break: break-all;
          white-space: pre-line;
        }

        &.check {
          position: relative;

          > label {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            > input {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        &.name {
          > a span {
            &:hover {
              text-decoration: underline;
            }
          }

          span {
            vertical-align: middle;

            &.badge {
              margin-left: $px5;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding-top: $px18;
  }

  .page {
    margin-top: $px25;
  }

  &[data-project-type=reward] {
    .content table {
      th {
        &.amount, &.pay {
          width: $px100;
        }

        &.date {
          width: $px113;
        }
      }

      td {
        &.date > span {
          word-break: break-word;
        }
      }
    }
  }

  &.skeleton {
    table {
      th, td {
        &.check {
          display: none;
        }

        > span, > a, > a span {
          @include skeleton
        }
      }
    }
  }

  @media (max-width: 991px) {
    .content table td > span {
      word-break: normal;
      white-space: nowrap;
    }
  }
}
</style>