<template>
  <div class="manage-investor-actions">
    <button class="btn btn-secondary font-xs" :disabled="!loaded" @click="openMessageModal()">
      <span>메시지 보내기</span>
      <span class="text-primary" v-if="sequences.length">&nbsp; {{ sequences.length }}</span>
    </button>
    <select class="form-control border-focus-point" v-model="args.order" @change="load()" :disabled="!loaded">
      <option value="latest">최신순</option>
      <template v-if="projectType === 'invest'">
        <option value="amount">투자 금액순</option>
        <option value="cd">투자 유형순</option>
      </template>
      <template v-else>
        <option value="amount">후원 금액순</option>
        <option value="title">리워드순</option>
        <option value="payMethod">결제 수단순</option>
      </template>
    </select>
    <div class="input-group">
      <div class="input-group-prepend">
        <select class="form-control border-focus-point" v-model="args.searchCondition" :disabled="!loaded">
          <template v-if="projectType === 'invest'">
            <option value="investorName">투자자명</option>
            <option value="investorId">아이디</option>
          </template>
          <template v-else>
            <option value="memberName">후원자명</option>
            <option value="memberId">아이디</option>
          </template>
        </select>
      </div>
      <input type="text" class="form-control font-sm border-focus-point" v-model="args.searchKeyword" @keyup.enter="load()" :disabled="!loaded"/>
      <div class="input-group-append pointer">
        <button class="btn btn-secondary font-xs" @click="load()" :disabled="!loaded">검색</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentManageInvestorActions";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    projectType: String,
    args: Object,
    sequences: Array,
    openMessageModal: Function,
    load: Function,
    loaded: Boolean,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.manage-investor-actions {
  height: $px42;

  > .btn {
    border: $px1 solid #eee;
    box-shadow: none;
    height: 100%;
  }

  > select {
    float: right;
    font-size: $px13;
    height: 100%;
    width: $px105;
    margin-left: $px15;
  }

  > .input-group {
    float: right;
    width: $px300;
    height: 100%;

    > .input-group-prepend {
      width: $px100;
      height: 100%;

      > select {
        font-size: $px13;
        height: 100%;
        border-radius: $px4 0 0 $px4;

        &:focus {
          position: relative;
          z-index: 1;
        }
      }
    }

    > input {
      font-size: $px13;
      height: 100%;
    }

    > .input-group-append {
      height: 100%;

      > .btn {
        height: 100%;
        border: $px1 solid #dee2e6;
        border-left: 0;
        box-shadow: none;

        &[disabled] {
          background: #e9ecef;
          opacity: 1;
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .input-group {
      width: 100%;
      margin: $px10 0;
    }
  }
}
</style>